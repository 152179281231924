var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"con_contain"},[_c('div',{staticClass:"mainpage"},[_c('div',{staticClass:"titlecategory"},[(!_vm.isVirtual)?_c('div',{staticClass:"titlecategory-item"},[_c('div',{staticClass:"item-left"},[_vm._v("学校")]),_c('div',{staticClass:"item-right"},[_c('div',{class:_vm.queryParams.school_id === ''
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.academybtclick('')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.schoollist),function(item,index){return _c('div',{key:index,class:_vm.queryParams.school_id === item.id
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.academybtclick(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]):_c('div',{staticClass:"titlecategory-item"},[_c('div',{staticClass:"item-left"},[_vm._v("课程类别")]),_c('div',{staticClass:"item-right"},_vm._l((_vm.inSchoolList),function(item,index){return _c('div',{key:index,class:_vm.queryParams.is_in_school == item.id
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.academybtclick(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"titlecategory-item"},[_c('div',{staticClass:"item-left"},[_vm._v("学科分类")]),_c('div',{staticClass:"item-right"},[_c('div',{class:_vm.queryParams.subject_id === ''
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.subjectbtclick('')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.subjectclassification),function(item,index){return _c('div',{key:index,class:_vm.queryParams.subject_id === item.id
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.subjectbtclick(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]),(!_vm.isVirtual)?_c('div',{staticClass:"titlecategory-item"},[_c('div',{staticClass:"item-left"},[_vm._v("项目级别")]),_c('div',{staticClass:"item-right"},[_c('div',{class:_vm.queryParams.level_id === ''
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.itemlevelbtclick('')}}},[_vm._v(" 全部 ")]),_vm._l((_vm.itemlevel),function(item,index){return _c('div',{key:index,class:_vm.queryParams.level_id === item.id
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.itemlevelbtclick(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]):_vm._e(),_c('div',{staticClass:"titlecategory-item"},[_c('div',{staticClass:"item-left"},[_vm._v("排序")]),_c('div',{staticClass:"item-right"},[_c('div',{class:_vm.queryParams.sort_type == 2
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.sortbtclick(2)}}},[_vm._v(" 加入人数最多 ")]),_c('div',{class:_vm.queryParams.sort_type == 1
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.sortbtclick(1)}}},[_vm._v(" 最新上线 ")]),_c('div',{class:_vm.queryParams.sort_type == 3
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text',on:{"click":function($event){$event.stopPropagation();return _vm.sortbtclick(3)}}},[_vm._v(" 收藏最多 ")])])])]),_c('div',{staticClass:"image-contain"},[_c('div',{staticClass:"image-item"},_vm._l((_vm.vrlist),function(item,index){return _c('div',{key:index,staticClass:"image-item-item",on:{"click":function($event){return _vm.todetail(item.id)}}},[_c('div',{staticClass:"card-contain"},[_c('div',{staticClass:"china_class_main"},[(item.level == '国家级')?[_c('el-image',{staticClass:"elimage",attrs:{"src":item.cover}}),_c('div',{staticClass:"china_class"},[_c('span',[_vm._v(_vm._s(item.level))])])]:(item.level == '校级')?[_c('el-image',{staticClass:"elimage",attrs:{"src":item.cover}}),_c('div',{staticClass:"china_class_1"},[_c('span',[_vm._v(_vm._s(item.level))])])]:(item.level == '省级')?[_c('el-image',{staticClass:"elimage",attrs:{"src":item.cover}}),_c('div',{staticClass:"china_class_2"},[_c('span',[_vm._v(_vm._s(item.level))])])]:[_c('el-image',{staticClass:"elimage",attrs:{"src":item.cover}})]],2),_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"title2"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.leader_name)+" "),((item.leader_name && item.school_name))?_c('span',{staticStyle:{"padding":"0px 4px"}},[_vm._v("|")]):_vm._e(),_vm._v(" "+_vm._s(item.school_name))]),_c('div',{staticClass:"rightimage"},[_c('el-image',{staticClass:"elimage",attrs:{"src":_vm.image3}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("cutNumber")(item.join_count))+"人参与")])],1)])])])}),0),_c('div',{staticClass:"elpagination"},[_c('div',{staticClass:"elpagination-title"},[_vm._v("共"+_vm._s(_vm.total)+"条")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"current-page":_vm.queryParams.page,"page-size":36},on:{"current-change":_vm.pageChange}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }